import React from "react";
import { Context as NavigationContext } from "../../provider/navigation";
import { Context as ContentContext } from "../../provider/content";
import SpeakersPhoto from "../../assets/backgrounds/speakers.jpg";
import { LinkButton } from "../../components/button";

import "./speakers.scss";

const Speaker = (speaker) => {
  return (
    <div className="speakers-list-speaker" key={speaker.id}>
      <div
        className="photo"
        style={{
          "background-image": `url(https://api.vera.school/${speaker.photo_url})`,
        }}
      ></div>
      <div className="text">
        <div className="title">
          <h3>{speaker.name}</h3>
          <h4>{speaker.position}</h4>
        </div>
        <div className="description">
          <p>{speaker.lead}</p>
        </div>
      </div>
    </div>
  );
};

const SpeakersPage = () => {
  const { setBackground, setTheme } = React.useContext(NavigationContext);
  const { speakers } = React.useContext(ContentContext);

  setTheme("transparent");
  setBackground(SpeakersPhoto);

  return (
    <div className="speakers">
      <div className="row lead title">
        <h1>Спикеры</h1>
        <p>
          Спикерами школы станут ведущие специалисты, врачи, юристы,
          ВИЧ-активисты, представители сообществ и некоммерческих организаций.
        </p>
      </div>

      <div className="row speakers-list">
        {speakers && speakers.map((speaker) => <Speaker {...speaker} />)}
      </div>

      {/*
            <div className="row participants-registration">
        <LinkButton to="/registration">Подать заявку на участие</LinkButton>
      </div>
      */}
    </div>
  );
};

export default SpeakersPage;
