import React from "react";
import { Link } from "@reach/router";
import { Context as NavigationContext } from "../../provider/navigation";
import AboutPhoto from "../../assets/backgrounds/about.jpg";
import Breaker from "../../assets/icons/nav-item-breaker.png";
import BreakerDark from "../../assets/icons/nav-item-breaker-dark.png";
import { LinkButton } from "../button";

import "./navigation.scss";

const Navigation = ({ theme: mainTheme }) => {
  const { theme, backgroundPhoto } = React.useContext(NavigationContext);

  if (!mainTheme) {
    mainTheme = theme;
  }

  if (mainTheme === "hidden") {
    return null;
  }

  const BreakerElement = (
    <img src={mainTheme === "white" ? BreakerDark : Breaker} alt="Breaker" />
  );

  return (
    <div className={`navigation ${mainTheme}`}>
      <nav>
        <Link to="/participants">Участникам</Link>
        {BreakerElement}
        <Link to="/speakers">Спикеры</Link>
        {BreakerElement}
        <Link to="/feedbacks">Отзывы</Link>
        {BreakerElement}
        <Link to="/school">Онлайн-школа</Link>
        {BreakerElement}
        <Link to="/contacts">Контакты</Link>
      </nav>
      <img
        className="navigation-photo"
        src={backgroundPhoto || AboutPhoto}
        alt="Photo"
      />
    </div>
  );
};

const MobileNavigation = ({ nextSchool, logo }) => {
  const [showMenu, setMenuShowing] = React.useState(false);
  return (
    <div className={`mobile-navigation ${showMenu && `active`}`}>
      <div className="title">
        <div
          className={showMenu ? "close-icon" : "open-icon"}
          onClick={() => setMenuShowing(!showMenu)}
        >
          {" "}
        </div>
        Школа молодого врача
      </div>
      {showMenu && (
        <div
          className={`menu ${showMenu && `active`}`}
          onClick={() => setMenuShowing(false)}
        >
          <div className="logo">{logo}</div>
          <nav>
            <Link to="/participants">Участникам</Link>
            <Link to="/speakers">Спикеры</Link>
            <Link to="/feedbacks">Отзывы</Link>
            <Link to="/school">Онлайн-школа</Link>
            <Link to="/contacts">Контакты</Link>
          </nav>
          <div></div>
          {/* enable when open and delete div above
                    <LinkButton to="/registration">Подать заявку на участие</LinkButton>
           */}
        </div>
      )}
    </div>
  );
};

export default Navigation;

export { MobileNavigation };
