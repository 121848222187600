import React from 'react'

const Context = React.createContext({
  theme: 'transparent',
  setTheme: () => {}
});

const Provider = ({ children }) => {

  const [theme, setTheme] = React.useState('transparent')
  const [backgroundPhoto, setBackground] = React.useState('')

  return (
    <Context.Provider
      value={{
        theme,
        setTheme,
        backgroundPhoto,
        setBackground
      }
    }>
      {children}
    </Context.Provider>
  )
}

export { Context, Provider }