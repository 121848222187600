const API  = "https://api.vera.school";

const fetchGet = (url) => {
  return fetch(url, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, cors, *same-origin
  })
  .then(response => {
    return response.json()
  })
  .then(result => {
    return result.data
  })
  .catch(err => console.error(err))
}

const ContentService = {
  getReviews: () => {
    return fetchGet(`${API}/reviews`)
  },
  getLessons: () => {
    return fetchGet(`${API}/lessons`)
  },
  getSpeakers: () => {
    return fetchGet(`${API}/speakers`)
  },
  getSettings: () => {
    return fetchGet(`${API}/settings`)
  },
  getArticles: () => {
    return fetchGet(`${API}/articles`)
  }
}

export default ContentService;
