import React from 'react'
import { Context as NavigationContext } from '../../provider/navigation'
import { LinkButton } from '../../components/button'

import './notFound.scss';

const NotFoundPage = () => {

  const { setTheme } = React.useContext(NavigationContext)

  setTheme('hidden')

  return (
    <div className="notFound">
      <h1>Ошибка 404</h1>
      <p>Не правильно набран адрес, или такой страницы на сайте больше не существует</p>
      <LinkButton to="/">
        На главную
      </LinkButton>
    </div>
  );
}

export default NotFoundPage