import React from "react";
import Navigation from "../navigation";
import Icons from "../icons";
import { Context as ContentContext } from "../../provider/content";

import "./footer.scss";

const Footer = () => {
  const { settings } = React.useContext(ContentContext);

  const { social = {} } = settings || {};

  const AidsShare = (
    <div className="footer-share-group aids">
      <a href={social.aids || "https://spid.center"} target="_blank">
        <img src={Icons.AidsIcon} alt="Aids Icon" />
      </a>
    </div>
  );

  return (
    <div className="footer">
      <div className="footer-share">
        {AidsShare}
        <div className="footer-share-group">
          <a href={social.facebook || "/"} target="_blank">
            <img src={Icons.FacebookIcon} alt="Facebook Icon" />
          </a>
          <a href={social.vk || "https://vk.com/spid.center"} target="_blank">
            <img src={Icons.VKIcon} alt="VK Icon" />
          </a>
        </div>
        {AidsShare}
        <div className="footer-share-group">
          <a href={social.instagram || "/"} target="_blank">
            <img src={Icons.InstagramIcon} alt="Instagram Icon" />
          </a>
          <a href={social.twitter || "/"} target="_blank" className="twitter">
            <img src={Icons.TwitterIcon} alt="Twitter Icon" />
          </a>
        </div>
      </div>
      <div className="footer-contacts">
        <div>Школа молодого врача</div>
      </div>
      <Navigation theme={"white"} />
    </div>
  );
};

export default Footer;
