import React from 'react'
import { Link } from '@reach/router'

import "./button.scss"

const Button = ({ children, disabled, ...props }) => (
  <button 
    className={`button ${disabled && `disabled`}`} 
    {...props}
  >
    {children}
  </button>
)

const LinkButton = ({
  to,
  state,
  children
}) => (
  <Link to={to} state={state}>
    <Button>
      {children}
    </Button>
  </Link>
)

export { Button, LinkButton }