import React from "react";
import { Context as ContentContext } from "../../provider/content";
import { Context as NavigationContext } from "../../provider/navigation";
import AboutPhoto from "../../assets/backgrounds/about.jpg";
import AuthorBrezhneva from "../../assets/author-brezhneva.png";
import AuthorEremin from "../../assets/author-eremin.png";
import Gallery from "../../components/gallery";
import Blockquote from "../../components/blockquote";
import { LinkButton } from "../../components/button";
import Photos from "../../components/photos";

import "./about.scss";

const AboutPage = () => {
  const { setBackground, setTheme } = React.useContext(NavigationContext);
  const { articles, settings: { school: { date } = {} } = {} } =
    React.useContext(ContentContext);

  setTheme("transparent");
  setBackground(AboutPhoto);

  const [pages, setPages] = React.useState();
  React.useLayoutEffect(() => {
    function updatePages() {
      setPages(window.innerWidth >= 1024 ? 2 : 1);
    }

    window.addEventListener("resize", updatePages);
    updatePages();
    return () => window.removeEventListener("resize", updatePages);
  }, []);

  return (
    <div className="about">
      {/* <img src={SharedImage} alt=""/> */}
      <div className="row lead">
        <h1>Школа молодого врача</h1>

        <a href="/registration">
          <div className="next-school">
            <span class="text">Следующая школа</span>
            <br />
            <span class="date">{date}</span>
          </div>
        </a>

        <p>
          Просветительский проект фонда СПИД.ЦЕНТР: «ВИЧ-инфекция для будущих
          врачей различных специальностей» — уникальный. Он объединяет
          студентов, выпускников медицинских вузов, практикующих врачей и
          экспертов, обучая их не только клиническим навыкам, но и гуманному
          отношению к пациентам, живущим с ВИЧ.
          <br />
          <br />
          Мы учим врачей избегать стигматизирующей терминологии и понимать права
          и потребности своих пациентов. Этот проект действительно меняет
          медицинскую практику в России, помогая врачам видеть не только
          диагноз, но и человека.
          <br />
          <br />
          Работа нашей школы влияет не только на пациентов с ВИЧ. Когда врачи
          становятся более осведомлёнными и эмпатичными, это изменяет систему
          здравоохранения в целом. Это касается каждого из нас, независимо от
          статуса. Более внимательное и уважительное отношение врачей к своим
          пациентам улучшает общее качество медицинской помощи, что положительно
          сказывается на здоровье всего общества.
          <br />
          <br />
          Вклад в этот проект — это вклад в будущее всех, кто живет в России.
          Школа молодого врача дает надежду в это непростое время и вселяет веру
          в светлое будущее нашей страны.
        </p>
      </div>

      <Gallery
        items={Object.values(Photos).map((photo) => ({ photo }))}
        itemsPerPage={1}
      />

      <Blockquote
        text={`Основная задача организаторов — сделать так, чтобы современные врачи забыли про «чуму XX века»
          и обратились к актуальным знаниям и научным достижениям.`}
        authorName="Антон Еремин"
        authorDesc="Фонд «СПИД.ЦЕНТР»"
        authorPhoto={AuthorEremin}
      />

      <Gallery
        items={
          articles
            ? articles.map((article) => ({
                photo: `https://api.vera.school/${article.photo_url}`,
                content: {
                  date: article.dates,
                  title: article.title,
                  desc: article.lead,
                  link: article.url_sc,
                  unaidsLink: article.url_unaids,
                },
              }))
            : []
        }
        itemsPerPage={pages}
      />

      <div className="row">
        С 2018 года Школа молодого врача проходит четыре раза в год. На каждый
        поток производится отбор 25 медицинских студентов, ординаторов и молодых
        врачей из разных регионов России и стран региона Восточной Европы и
        Центральной Азии.
        <br />
        <br />
        Конечной целью этого проекта является повышение уровня осведомленности
        будущих врачей по теме ВИЧ-инфекции и снижение уровня дискриминации в
        сфере здравоохранения.
      </div>

      <div className="row about-video">
        <div className="about-video-wrapper">
          <iframe
            width="980"
            height="500"
            src="https://www.youtube.com/embed/yko0Fsu2BmI"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>
        <div className="about-video-desc">
          Видео Ольги Пендриковой (Гомель), участвовавшей в 4 сезоне школы
          молодых врачей.
        </div>
      </div>

      {/* enable when open
                <div className="row participants-registration">
        <LinkButton to="/registration">Подать заявку на участие</LinkButton>
      </div>
          */}
    </div>
  );
};

export default AboutPage;
