import React from 'react'
import { Context as NavigationContext } from '../../provider/navigation'
import { LinkButton } from '../../components/button'

import './formFilled.scss';

const FormFilledPage = () => {

  const { setTheme } = React.useContext(NavigationContext)

  setTheme('hidden')

  return (
    <div className="formFilled">
      <div className="row">
        <h1>Вы заполнили форму, теперь возвращайтесь на главную</h1>
        <LinkButton to="/">
          На главную
        </LinkButton>
      </div>
    </div>
  );
}

export default FormFilledPage