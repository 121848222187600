import React from 'react'
import Arrow from "../../assets/icons/link-arrow.png"
import "./gallery.scss"

const Gallery = ({ items, itemsPerPage }) => {

  const [activePage, setActivePage] = React.useState(0);

  const getPages = (activePage = 0) => {
    const pages = []
    let offset = 0
    for(let i = 0; i < items.length / itemsPerPage; i++){
      pages.push(
        <div
          className={`page ${activePage === i && 'active'}`}
          onClick={() => switchPage('next')}
        >
          {items.map(({ photo, content }, idx) => {
            if(idx >= offset && idx < offset + itemsPerPage){
              return (
                <div
                  className="item"
                  style={{ width: itemsPerPage === 2 ? '48%' : '100%' }}
                  onTouchStart={onItemTouchStart}
                  onTouchEnd={onItemTouchEnd}
                >
                  <div className="photo">
                    <img
                      src={photo}
                      className={content && 'with-content'}
                    />
                  </div>
                  {content && (
                    <div className="content">
                      <div className="date">
                        {content.date}
                      </div>
                      <div className="title">
                        {content.title}
                      </div>
                      <div className="desc">
                        {content.desc}
                      </div>
                      <div className="link">
                        <a 
                          href={content.link}
                          target="_blank"
                        >
                          читать на сайте фонда <img src={Arrow}/>
                        </a>
                      </div>
                      {content.unaidsLink && (
                        <div className="link">
                          <a 
                            href={content.unaidsLink}
                            target="_blank">
                              читать на сайте unaids.org <img src={Arrow}/>
                          </a>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )
            }
          })}
        </div>
      )
      offset += itemsPerPage
    }

    return pages;
  };

  const switchPage = (dir) => {
    const step = Math.round(items.length / itemsPerPage);
    setActivePage((activePage + (dir === 'next' ? 1 : -1 ) + step) % step);
  }

  const getPagiantion = (activePage = 0) => {
    const pages = []
    for(let i = 0; i < items.length / itemsPerPage; i++){
      pages.push(
        <div className={`ellipse ${activePage === i && 'active'}`} onClick={() => setActivePage(i)}/>
      )
    }
    return pages;
  }

  let touchStartPosition;

  const onItemTouchStart = (e) => {
    touchStartPosition = e.changedTouches[0].screenX;
  }

  const onItemTouchEnd = (e) => {
    if(e.changedTouches[0].screenX > touchStartPosition) {
      switchPage('prev');
    } else if(e.changedTouches[0].screenX < touchStartPosition) {
      switchPage('next');
    }
  }

  return (
    <div className="gallery">
      <div className="row">
        <div className="gallery-list">
          {getPages(activePage)}
        </div>
        <div className="controls">
          <div className="left" onClick={() => switchPage('prev')}></div>
          <div className="right" onClick={() => switchPage('next')}></div>
        </div>
        <div className="pagination">
          {getPagiantion(activePage)}
        </div>
        <div className="desc row">
          Предыдущие наборы школы
        </div>
      </div>
    </div>
  )
}

export default Gallery
