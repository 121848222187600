import React from 'react'
import './list.scss'

const List = ({ blocks }) => {
  return (
    <div className="list">
        {blocks.map((block, idx) => (
          <div>
            <h1>{idx + 1}</h1>
            <p>
              {block}
            </p>
          </div>
        ))}
      </div>
  )
}

export default List
