import InstagramIcon from "../../assets/icons/instagram.svg";
import TwitterIcon from "../../assets/icons/twitter.png";
import AidsIcon from "../../assets/icons/aids.png";
import FacebookIcon from "../../assets/icons/facebook.svg";
import TelegramIcon from "../../assets/icons/telegram.png";
import PhoneIcon from "../../assets/icons/phone.png";
import EmailIcon from "../../assets/icons/email.png";
import AddressIcon from "../../assets/icons/address.png";
import VKIcon from "../../assets/icons/vk.svg";

export default { 
  InstagramIcon,
  TwitterIcon,
  AidsIcon,
  FacebookIcon,
  TelegramIcon,
  PhoneIcon,
  EmailIcon,
  AddressIcon,
  VKIcon
}