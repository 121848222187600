import React from "react";
import { Context as NavigationContext } from "../../provider/navigation";
import Blockquote from "../../components/blockquote";

import "./lesson.scss";

const getDefaultLessonContent = () => {
  return (
    <>
      <p>
        Бесплатная для участников (включена обучающая программа, двухразовое
        питание, проживание в отеле на 7 дней, сувениры участникам; студентом
        самостоятельно оплачивается только проезд из своего города в Москву и
        обратно). К участию приглашаются медицинские студенты и ординаторы из
        всех ВУЗов России независимо от будущей врачебной специальности.
        Подробный курс по теме ВИЧ-инфекции позволит участникам узнать как
        базовую информацию о ВИЧ-инфекции, так и социальные, юридические аспекты
        данной проблемы. Бесплатная для участников (включена обучающая
        программа, двухразовое питание, проживание в отеле на 7 дней, сувениры
        участникам; студентом самостоятельно оплачивается только проезд из
        своего города в Москву и обратно). К участию приглашаются медицинские
        студенты и ординаторы из всех ВУЗов России независимо от будущей
        врачебной специальности. 3. Подробный курс по теме ВИЧ-инфекции позволит
        участникам узнать как базовую информацию о ВИЧ-инфекции, так и
        социальные, юридические аспекты данной проблемы.
      </p>

      <Blockquote>
        Спикерами школы станут ведущие специалисты, врачи, юристы,
        ВИЧ-активисты, представители сообществ, представители НКО. Спикерами
        школы станут ведущие специалисты, врачи, юристы
      </Blockquote>

      <ul>
        <li>
          Фонд СПИД.ЦЕНТР принимает заявки для участия в просветительском
          проекте Школа молодого врача, который будет{" "}
        </li>
        <li>
          Фонд СПИД.ЦЕНТР принимает заявки для участия в просветительском
          проекте Школа молодого врача, который будет{" "}
        </li>
      </ul>
    </>
  );
};

const Lesson = ({ location: { state } }) => {
  const { setTheme } = React.useContext(NavigationContext);

  setTheme("blue");

  return (
    <div className="primary lesson">
      <div className="lesson-video">
        <iframe
          width="980"
          height="500"
          src={state.youtube}
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>

      <h1>{state.course && state.course.name}</h1>

      {state.content || getDefaultLessonContent()}
    </div>
  );
};

export default Lesson;
