import React from 'react'
import ContentService from '../service/content'

const Context = React.createContext({});

const Provider = ({ children }) => {

  const { getLessons, getReviews, getSettings, getSpeakers, getArticles } = ContentService;

  const [content, setContent] = React.useState({});

  React.useEffect(() => {
    Promise.all([
      getLessons(),
      getReviews(),
      getSettings(),
      getSpeakers(),
      getArticles(),
    ])
    .then(results => {
      
      setContent({
        lessons: results[0],
        reviews: results[1],
        settings: results[2],
        speakers: results[3],
        articles: results[4],
      })

    })
    .catch(err => console.error(err))
  }, [])

  return (
    <Context.Provider
      value={{
        lessons: content.lessons,
        reviews: content.reviews,
        settings: content.settings,
        speakers: content.speakers,
        articles: content.articles,
        getContent: () => content,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export { Context, Provider }
