import React from "react";
import { Context as NavigationContext } from "../../provider/navigation";
import Blockquote from "../../components/blockquote";

import "./feedback.scss";

const getDefaultContent = () => (
  <>
    <p>
      Бесплатная для участников (включена обучающая программа, двухразовое
      питание, проживание в отеле на 7 дней, сувениры участникам; студентом
      самостоятельно оплачивается только проезд из своего города в Москву и
      обратно). <br />
      К участию приглашаются медицинские студенты и ординаторы из всех ВУЗов
      России независимо от будущей врачебной специальности. <br />
      Подробный курс по теме ВИЧ-инфекции позволит участникам узнать как базовую
      информацию о ВИЧ-инфекции, так и социальные, юридические аспекты данной
      проблемы. <br />
      Бесплатная для участников (включена обучающая программа, двухразовое
      питание, проживание в отеле на 7 дней, сувениры участникам; студентом
      самостоятельно оплачивается только проезд из своего города в Москву и
      обратно). <br />
      К участию приглашаются медицинские студенты и ординаторы из всех ВУЗов
      России независимо от будущей врачебной специальности. <br />
      3. Подробный курс по теме ВИЧ-инфекции позволит участникам узнать как
      базовую информацию о ВИЧ-инфекции, так и социальные, юридические аспекты
      данной проблемы.
    </p>

    <Blockquote>
      Спикерами школы станут ведущие специалисты, врачи, юристы, ВИЧ-активисты,
      представители сообществ, представители НКО. Спикерами школы станут ведущие
      специалисты, врачи, юристы
    </Blockquote>

    <ul>
      <li>
        Фонд СПИД.ЦЕНТР принимает заявки для участия в просветительском проекте
        Школа молодого врача, который будет{" "}
      </li>
      <li>
        Фонд СПИД.ЦЕНТР принимает заявки для участия в просветительском проекте
        Школа молодого врача, который будет{" "}
      </li>
    </ul>
  </>
);

const Feedback = ({ location }) => {
  const { setTheme } = React.useContext(NavigationContext);

  setTheme("blue");

  const review = location.state;

  return (
    <div className="primary feedback">
      <div className="header">
        <div className="photo">
          <img
            src={`https://api.vera.school/${review.photo_url}`}
            alt="Photo"
          />
        </div>
        <div className="description">
          <h1>{review.name}</h1>
        </div>
      </div>

      <div className="content">{review.content || getDefaultContent()}</div>
    </div>
  );
};

export default Feedback;
